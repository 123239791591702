@import './../lib/vars';

.b-button {
  line-height: 1;
  padding: 13px 0;
  font-size: 16px;
  font-family: 'Roboto';
  letter-spacing: 1px;
  font-weight: 500;
  width: 100px;
  border-radius: 21px;
  margin-top: 15px;
  cursor: pointer;
}
.red-button {
  color: #fff;
  background: $color-primary;
  border: 2px solid $color-primary;
}
.red-button:hover {
  color: $color-primary;
  background: #fff;
  border-width: 2px;
  font-weight: 900;
}
.dark-button {
  color: #4a4a4a;
  border-color: #4a4a4a;
  background: #fff;
  font-weight: 900;
  border-width: 2px;
}
.dark-button:hover {
  background: #4a4a4a;
  color: #fff;
  font-weight: 500;
}
.ml20 {
  margin-left: 20px;
}
.textRight {
  text-align: right;
}
