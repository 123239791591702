$color-primary: #ff001f;
$color-primary-hover: rgba(211, 211, 211, 1);
$color-secondary: rgb(33, 153, 33);
$color-secondary-hover: rgba(33, 153, 33, 0.5);
$color-error: #ff777a;
$color-success: #77ff7a;
$color-white: #fff;
$color-black: #000;

$color-gray: #b4b4b4;
$color-gray-hover: rgba(180, 180, 180, 0.5);
$color-lightgray: #e9e9e9;
$light-gray: #4a4a4a;

$pad: 0.7vw;
$margin: 0.7vw;

$font-primary: 'Lato', sans-serif;
$font-secondary: 'Roboto', 'Oswald', sans-serif;

$font-lg: 5.2vw;
$font-md: 3.2vw;

$border-radius: 0.5vw;

$dg: #4a4a4a;
$lg: #9b9b9b;
$ghover: #b4b4b4;

// checkmark
$color--green: #7ac142;
$curve: cubic-bezier(0.65, 0, 0.45, 1);
