// keep
@import './../lib/vars';

.joinOuter {
  min-height: 104px;
  padding-bottom: 16px;
  position: relative;
  cursor: pointer;
}
.joinWrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -moz-box-flex: 1;
  -moz-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-flex-wrap: none;
  flex-wrap: nowrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;

  justify-content: space-between;
  overflow: hidden;
  min-height: 104px;
  border-radius: 10px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16);
  margin: 0 16px;
  position: relative;
}
.joinTextDiv {
  -webkit-box-ordinal-group: 3;
  -webkit-order: 2;
  -moz-order: 2;
  -ms-flex-order: 2;
  order: 2;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 100%;
  -moz-box-flex: 1;
  -moz-flex: 1 1 100%;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  padding: 12px;
  position: relative;
}
.joinTextTitle {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  max-height: calc((24px * 2) + 2px);
  overflow: hidden;
  text-overflow: ellipsis;
  color: #1d1e1f;
  font-size: 19px;
  line-height: 21px;
  font-weight: 600;
  display: block;
  white-space: nowrap;
}
.landing-page .joinTextDiv .joinTextTitle {
  white-space: normal;
  max-height: 44px;
  display: block;
  display: -webkit-box;
}
.joinTextSubtitle {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  max-height: calc((19px * 2) + 4px);
  overflow: hidden;
  text-overflow: ellipsis;
  color: #48494a;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}
.joinImgDiv {
  margin-top: 0;
  height: 104px;
  width: 104px;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-ordinal-group: 1;
  -webkit-order: 0;
  -moz-order: 0;
  -ms-flex-order: 0;
  order: 0;
  margin-top: 0;
  overflow: hidden;
  border-radius: 10px 0 0 10px;
  position: relative;
  background: #e1e1e1;
}
.joingImg {
  display: block;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  text-align: center;
  margin: auto;
}
.joinOuter:hover .joinTextTitle,
.createMainWrapper:hover .createMainTitle {
  color: #06c;
}
@media only screen and (max-width: 767px) {
  .stephJoinImg {
    margin-left: -50%;
  }
}
@media only screen and (min-width: 585px) {
  .joinTextDiv .joinTextSubtitle {
    -webkit-line-clamp: unset;
    max-height: none;
  }
}
@media only screen and (min-width: 768px) {
  .joinOuter {
    height: 150px;
  }
  .joinWrapper {
    min-height: 134px;
  }
  .joinTextDiv {
    max-height: 134px;
  }
  .joinTextTitle {
    margin-bottom: 10px;
  }
  .joinImgDiv {
    height: 134px;
    width: 238px;
  }
  .joingImg {
    min-height: 134px;
  }
}
