.contentOuter {
  background: #fff;
  border-radius: 10px;
  margin-bottom: 16px;
  overflow: hidden;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.24);
}
.contentInner {
  border-radius: 10px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16);
  margin: 0 16px 16px;
}
.pad-16 {
  padding-right: 16px;
  padding-left: 16px;
}
.mar-16 {
  margin-right: 16px;
  margin-left: 16px;
}
.contentHeaderOuter {
  margin-top: 20px;
}
.contentHeader {
  font-weight: 900;
  text-transform: uppercase;
  color: #1d1e1f;
  font-size: 30px;
  text-align: center;
}
.leaguesContainerHeader .users {
  margin-right: 10px;
  width: 40px;
  height: 40px;
}
@media only screen and (min-width: 768px) {
  .contentOuter {
    box-shadow: none;
  }
}
@media only screen and (min-width: 992px) {
  .marginTopLarge {
    margin-top: -219px;
  }
  .marginTopL57 {
    margin-top: -22px;
  }
}
@media only screen and (min-width: 1200px) {
  .marginTopLarge {
    margin-top: -202px;
  }
  .marginTopL57 {
    margin-top: -41px;
  }
}
