.allHeader {
  font-size: 30px;
  font-weight: 900;
  margin: 20px 12px 20px 32px;
  padding-bottom: 12px;
  color: #1d1e1f;
}
.all-private-form {
  margin-top: 16px;
  width: 100%;
  display: block;

  input {
    width: calc((100% - 64px));
    margin-left: 32px;
    margin-right: 32px;
  }
  .joinPrivate {
    margin-right: 32px;
    margin-bottom: 16px;
  }
}
.allTableOuter {
  margin: 0 16px 16px 16px;
}
.tableHeader {
  font-size: 30px;
  font-weight: 900;
  padding: 20px 12px 20px 32px;
  margin-bottom: 12px;
  color: #1d1e1f;
}
.hideMedium {
  .mainContainer {
    width: 294px;
    margin-left: 0;

    .mainContainerSectionWrapper {
      box-shadow: none;
      margin: 0;
      border-radius: 0;
    }
    .allHeader {
      margin: 20px 16px;
      padding-bottom: 0;
    }
    .all-private-form input {
      width: calc((100% - 32px));
      margin-left: 16px;
      margin-right: 16px;
    }
    .all-private-form .joinPrivate {
      margin-right: 16px;
      margin-bottom: 0;
    }
  }
}

@media (min-width: 582px) {
  .allTableContainer p {
    width: 550px;
    margin: auto;
  }
}
@media (min-width: 660px) {
  .allTableOuter .allTableColumnDiv {
    padding: 0 39px;
  }
}
@media (min-width: 992px) {
  .hideLarge {
    display: none;
  }
}
@media (max-width: 1199px) and (min-width: 992px) {
  .allTableOuter .allTableColumnDiv {
    padding: 0 15px;
  }
}

@media (max-width: 991px) {
  .hideMedium {
    display: none;
  }
}
@media (min-width: 1200px) {
  .allTableOuter {
    width: auto !important;
  }
}
